<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>

    <section id="about" class="bg_lighgrey_pattern">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-12">
                <h3 class="title_xl title_lightblue">
                    Organizacija
                </h3>
                <p class="text_xl mt-4 mb-5">
                    Organizacija Serbian Researchers osniva se da bi <b>osnažila naučnu i inovacionu zajednicu</b>.<br><br>
                    Ovu viziju ostvarujemo kroz <b>kreiranje platforme za povezivanje naših istraživača</b> u Srbiji i inostranstvu,
                    koja omogućava deljenje znanja i iskustava između pojedinaca koji se bave naučno-istraživačkim radom.<br><br>
                    Kroz <b>povezivanje istraživača</b>, cilj platforme je i da doprinese <b>razmeni ideja i saradnji</b>
                    između akademskih institucija i kompanija.<br><br>
                    Postojanje ovakve platforme omogućava da široj javnosti, u zemlji i inostranstvu, prikažemo <b>rad naših članova</b> koji pomeraju granice nauke širom sveta.
                </p>
            </div>

            <div class="col-12 col-md-6 col-lg-4 card-container mb-4 mb-lg-0">
                <div class="card">
                    <div>
                        <figure>
                            <img class="img-fluid" src="@/assets/icons/analytics-icon.svg" alt="Saradnja" width="" height="">
                        </figure>
                        <span class="d-block title_l title_darkblue">Saradnja</span>
                    </div>
                    <p class="text_m">
                        Platforma koja olakšava i podstiče saradnju između domaćih i inostranih:
                    </p>
                    <ul>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">istraživača</p>
                        </li>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">akademskih institucija</p>
                        </li>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">kompanija</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 card-container mb-4 mb-lg-0">
                <div class="card">
                    <div>
                        <figure>
                            <img class="img-fluid" src="@/assets/icons/shape-3.svg" alt="Saradnja" width="" height="">
                        </figure>
                        <span class="d-block title_l title_darkblue">Edukacija</span>
                    </div>
                    <p class="text_m">
                        Blog, radionice i seminari u oblastima:
                    </p>
                    <ul>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">zaštite intelektualne svojine</p>
                        </li>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">prikupljanja kapitala za inovativne projekte</p>
                        </li>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">tehnike komunikacije naučnih rezultata</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 card-container mb-4 mb-lg-0">
                <div class="card">
                    <div>
                        <figure>
                            <img class="img-fluid" src="@/assets/icons/analytics-icon.svg" alt="Saradnja" width="" height="">
                        </figure>
                        <span class="d-block title_l title_darkblue">Analitika</span>
                    </div>
                    <p class="text_m">
                        Statistika zastupljenosti srpskih istraživača prema:
                    </p>
                    <ul>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">oblasti istraživanja</p>
                        </li>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">geografskoj lokaciji</p>
                        </li>
                        <li class="row">
                            <p class="col-1"> ● </p>
                            <p class="col-10 text_m">aktivnosti u naučnim časopisima i konferencijama</p>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>


    <section id="platform" class="membership-extended py-5">
      <div class="container">
        <div class="row my-5">
          <div class="col-12 col-lg-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
            <h3 class="title_xl">
              O platformi
            </h3>
            <p class="text_xl mt-4">
              Platforma Serbian Researchers pokrenuta je od strane <b>tri istraživača</b>, koja su primetila potrebu da se kreira platforma koja bi umrežila naše istraživače u svetu.
              <br><br>
              Serbian Researchers (“Srpski Istraživači”) je <b>neprofitno, nevladino i apolitično udruženje</b>, sa sedištem u Beogradu (MB: 28321406).
              <br><br>
              Platforma je <b>besplatna</b> za korišćenje.
              <br><br>
              Troškovi vođenja udruženja, razvoja i održavanja platforme, pokriveni su iz privatnih sredstava osnivača.
              <br><br>
              Za više detalja o biografijama osnivača, motivima pokretanja platforme i ciljevima,
              pogledajte sekciju <router-link to="/faq" class="text_xl"><u><b>Najčešća pitanja</b></u></router-link> ili
              pročitajte tekstove <router-link to="/blog" class="text_xl"><u><b>na našem blogu</b></u></router-link>.
            </p>
          </div>

        </div>
      </div>
    </section>


<section id="kako-da-postanete-clan" class="membership bg_lighgrey_pattern">
    <div class="container">
      <div class="row aos-init" data-aos="fade-up" data-aos-duration="1000">
          <h3 class="col-12 title_xl mb-4 title_lightblue">
            Članstvo u organizaciji
          </h3>
        <p class="col-12 text_xl ">
          Članstvo u organizaciji je isključivo moguće na osnovu preporuke nekog od članova.
        </p>
        <p class="col-12 text_xl">
		      Od članova se jedino očekuje da održavaju svoj profil ažurnim i da ukoliko su u mogućnosti izdvoje vreme za druge članove koji ih kontaktiraju.
		      <br><br>
		      Nadamo se da će članovi svojim prisustvom i zalaganjem postati svojevrsni ambasadori organizacije i time doprineti daljem razvoju srpske naučne zajednice, u čemu ćemo se truditi da im maksimalno pomognemo.
        </p>
	      <p class="col-12 text_xl">
			  Dovoljno je da zadovoljavate <strong>minimum jedan</strong> od sledeća 3 uslova:
	      </p>
      </div>
      <div class="conditions row aos-init" data-aos="fade-up" data-aos-duration="1000">
	      <ul class="col-12 mt-3">
		      <li class="row" data-aos="fade-up" data-aos-duration="1000">
			      <div class="col-12 col-lg ml-lg-3 mb-4 mb-lg-0 text-center">
				      <figure class="mt-5">
					      <img class="img-fluid" :src="require(`@/assets/icons/paper.svg`)" alt="" width="" height="">
				      </figure>

				      <p class="text_xl">Autor ste (ili koautor) objavljenog naučnog rada, bilo gde u svetu.</p>
			      </div>
			      <div class="col-12 col-lg mx-lg-5 mb-4 mb-lg-0 text-center">
				      <figure class="mt-5">
					      <img class="img-fluid" :src="require(`@/assets/icons/patent.svg`)" alt="" width="" height="">
				      </figure>
				      <p class="text_xl">Autor ste (ili koautor) pronalaska zaštićenog patentom, bilo gde u svetu.</p>
			      </div>

			      <div class="col-12 col-lg text-center  mr-lg-3">
				      <figure class=" mt-5">
					      <img class="img-fluid" :src="require(`@/assets/icons/phd.svg`)" alt="" width="" height="">
				      </figure>
				      <p class="text_xl">Doktorirali ste (ili ste i dalje student doktorskih studija) na akreditovanoj akademskoj instituciji u Srbiji ili inostranstvu.</p>
			      </div>
		      </li>
	      </ul>


      </div>
      </div>
    </section>

    <section id="sledeci-koraci" class="next">
    <div class="container aos-init" data-aos="fade-up" data-aos-duration="1000">
      <div class="row">
        <div class="col-12 col-lg-8">
          <h3 class="next__title">
            Sledeći koraci
          </h3>
          <p class="next__info mb-0 pr-4">
            Početkom 2024. godine, nakon što bude sakupljen dovoljan broj podataka, sajt će poprimiti
            svoju punu funkcionalnost. Prikupljeni podaci biće vidljivi u okviru pojedinačnih profila
            na zasebnim stranicama, dok će biti omogućena pretraga baze podataka po oblasti istraživanja,
            instituciji, lokaciji i ključnim rečima. Takođe, objavićemo prve konkretne korake u daljem radu
            sa ciljem povezivanja članova organizacije.
          </p>
          <p class=" next__contact pb-5">
            info@serbianresearchers.com
          </p>
        </div>


        <figure class="d-none d-lg-block col-lg-4 mb-0 pb-5">

        </figure>

      </div>
    </div>
  </section>

    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"


export default{
  name: "AboutView",
    components: {
    Navbar,
    Footer
  }
}

</script>
